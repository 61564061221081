// GeneralTermsScreen.tsx
import React from "react";
import { Link } from "react-router-dom";

const GeneralTermsScreen: React.FC = () => {
  return (
    <div style={{ padding: '30px', width: '60%', margin: 'auto' }}>
      <h1>Terms of Service</h1>
      <p>By accessing or using our services, you agree to be bound by these Terms of Service ("Terms"). If you do not agree with any part of these Terms, you must not use our services. Your use of our services signifies your acceptance of these Terms.</p>

      <h2>Description of Services</h2>
      <p>Our services are provided "as is" and "as available." We do not warrant that the services will be uninterrupted, timely, secure, or error-free. We reserve the right to modify, suspend, or discontinue the services at any time without notice.</p>

      <h2>No User Data Collection</h2>
      <p>We do not collect, store, or process any personal data from users. Our services are designed to operate without the need for personal information. You can use our services anonymously.</p>

      <h2>User Responsibilities</h2>
      <p>You agree to use our services only for lawful purposes and in accordance with these Terms. You are responsible for your use of the services and for any consequences thereof. You must not misuse our services, interfere with their normal operation, or access them using a method other than the interface and instructions we provide.</p>

      <h2>Third-Party Links</h2>
      <p>Our services may contain links to third-party websites or services that are not owned or controlled by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of any such content, goods, or services available on or through any such websites or services.</p>

      <h2>Limitation of Liability</h2>
      <p>To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use of or inability to use our services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from our services; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our services by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available through our services; and/or (vi) the defamatory, offensive, or illegal conduct of any third party. In no event shall our total liability to you for all damages, losses, and causes of action exceed the amount you paid us, if any, for using our services.</p>

      <h2>Indemnification</h2>
      <p>You agree to indemnify, defend, and hold harmless our company and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) that such parties may incur as a result of or arising from your (or anyone using your account's) violation of these Terms. We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate in asserting any available defenses.</p>

      <h2>Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which our company is established, without regard to its conflict of law provisions. You agree to submit to the personal and exclusive jurisdiction of the courts located within that jurisdiction.</p>

      <h2>Changes to Terms</h2>
      <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page. You are advised to review these Terms periodically for any changes. Changes to these Terms are effective when they are posted on this page. Your continued use of our services after any such changes constitutes your acceptance of the new Terms.</p>

      <h2>Termination</h2>
      <p>We may terminate or suspend your access to our services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms. Upon termination, your right to use the services will cease immediately.</p>

      <h2>Entire Agreement</h2>
      <p>These Terms constitute the entire agreement between you and us regarding the use of our services and supersede any prior agreements or understandings, whether written or oral, relating to such use. Any waiver of any provision of these Terms will be effective only if in writing and signed by an authorized representative of our company. If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions will continue in full force and effect.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us through our support channels. We are here to assist you and address any concerns you may have.</p>

      <p>By using our services, you acknowledge that you have read and understood these Terms and agree to be bound by them. If you do not agree to these Terms, please do not use our services. Your continued use of our services constitutes your acceptance of these Terms and any changes made to them.</p>
    </div>
  );
};

export default GeneralTermsScreen;
