// GeneralTermsScreen.tsx
import React from "react";

const GeneralTermsScreen: React.FC = () => {
  return (
    <div style={{padding:'30px', width:'60%', margin:'auto'}}>
      <h1>Privacy Policy</h1>
      <p>Welcome to our Privacy Policy. We are committed to maintaining the privacy and protection of your personal information. This document outlines our policies and practices regarding the collection, use, and disclosure of personal information when you use our services.</p>

      <h2>Information Collection and Use</h2>
      <p>We want to assure you that we do not collect or store any personal data from our users. Our services are designed to function without the need for personal information, and we have implemented measures to ensure that no user data is collected, stored, or processed in any manner.</p>

      <h2>No Data Storage</h2>
      <p>We explicitly state that we do not store any personal information or data from users who access or use our services. This includes, but is not limited to, names, addresses, email addresses, phone numbers, and any other personal identifiers. Our systems and processes are designed to function without the need for such information, ensuring your privacy is maintained at all times.</p>

      <h2>Third-Party Services</h2>
      <p>Our services may include links to third-party websites or services that are not operated by us. Please be aware that we have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. We encourage you to review the privacy policies of any third-party sites you visit.</p>

      <h2>Cookies and Tracking Technologies</h2>
      <p>We do not use cookies or any other tracking technologies to collect information about your activity on our services. We ensure that your use of our services is anonymous and that your privacy is fully protected.</p>

      <h2>Data Security</h2>
      <p>Although we do not collect or store any personal data, we have implemented appropriate technical and organizational measures to ensure that any data interactions with our services are secure and protected from unauthorized access, alteration, disclosure, or destruction.</p>

      <h2>Children’s Privacy</h2>
      <p>Our services do not address anyone under the age of 18. We do not knowingly collect personally identifiable information from children under 18. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us so that we can take necessary actions.</p>

      <h2>Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

      <h2>Limitation of Liability</h2>
      <p>By using our services, you agree that we shall not be held liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your use of our services. We make no warranties, express or implied, about the reliability or accuracy of our services, and you use our services at your own risk.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please contact us through our support channels. We are here to assist you and address any concerns you may have regarding our commitment to your privacy.</p>

      <h2>Final Provisions</h2>
      <p>This Privacy Policy constitutes the entire agreement between you and us regarding the use of our services and supersedes any prior agreements or understandings, whether written or oral, relating to such use. Any waiver of any provision of this Privacy Policy will be effective only if in writing and signed by an authorized representative of our company. If any provision of this Privacy Policy is held to be invalid or unenforceable, the remaining provisions will continue in full force and effect.</p>

      <p>Thank you for taking the time to read our Privacy Policy. We are committed to ensuring that your experience with our services is secure and respectful of your privacy.</p>
    </div>
  );
};

export default GeneralTermsScreen;
