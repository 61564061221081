// SupportForm.tsx
import React from "react";

const SupportForm: React.FC = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div >
        <h1 style={{ textAlign: "center" }}>Towbar support</h1>
        <p>
          For any support related questions, please contact us at
          <b> contact@tethertechnologies.com.au</b>
        </p>
      </div>
      {/* Your form code goes here */}
    </div>
  );
};

export default SupportForm;
