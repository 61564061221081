// TetherHomeScreen.tsx
import React from "react";
import { Link } from "react-router-dom";

const TetherHomeScreen: React.FC = () => {
  return (
    <div>
      <h1>This website is getting built...</h1>
      {/* <Link to="/towbar/support">Towbar Support</Link> */}
      {/* Your form code goes here */}
    </div>
  );
};

export default TetherHomeScreen;
