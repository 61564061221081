// App.tsx
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Your components
import TetherHomeScreen from "./screens/HomeScreen";
import SupportForm from "./screens/Towbar/SupportScreen";
import GeneralPrivacy from "./screens/GeneralPrivacy";
import GeneralTerms from "./screens/GeneralTerms";

// Component for redirecting to App Store
const AppStoreRedirect: React.FC = () => {
  useEffect(() => {
    // Set favicon
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = '../crossfade_icon.png'; // Ensure this path is correct

    // Append the new favicon
    document.head.appendChild(favicon);

    // Redirect to App Store
    window.location.href = "https://apps.apple.com/us/app/crossfade/id6538728650";
  }, []);

  return null;
};

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<TetherHomeScreen />} />
          <Route path="/towbar/support" element={<SupportForm />} />
          <Route path="/doc/privacy" element={<GeneralPrivacy />} />
          <Route path="/doc/terms" element={<GeneralTerms />} />
          <Route path="/crossfade" element={<AppStoreRedirect />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
